<template>
    <div class="layout">
        <div class="overlay" v-if="loading"></div>
        <div style="z-index: 100 !important;">
            <SideNavbar @button-click="langchange"></SideNavbar>
        </div>
        <div class="regdevice_contents">

            <div class="regdevice_header">
                {{ this.lang.title }}
            </div>
            <div class="dashboard_role">
                Current Role : {{ this.currentRole }}
            </div>
            <div class="regdevice_secondary_container">
                <p class="regdevice_secondary_header">{{ this.lang.second_title }}</p>
                <p class="contents_header_text">{{ this.lang.content }}</p>
            </div>
            <div class="searchbar_container form-group form_margin">
                <input type="text" class="form-control custom_input_form" id="devicename" :placeholder="this.lang.msisdn_hint" v-model="devicename" />
                <button type="submit" class="editacc_btn" @click="searchDevice">{{ this.lang.search_button }}</button>
                <div style="display: flex; width: 508px; margin-left: 30%;">
                    <label style="margin-top: 6px; font-family: 'Century Gothic Bold';">{{ this.lang.text_select_options }}: </label>
                    <select type="select-category" @change="refreshDeviceInfo"
                    class="form-control contact_input_form" id="select-device-category" v-model="select_device_option" style="max-width: 200px; max-height: 40px; margin-left: 10px; margin-bottom: 20px;">
                    <option value="ios">iOS</option>
                    <option value="ipados">iPadOS</option>
                    <option value="macos">MacOS</option>
                    </select>
                </div>
            </div>
            <div class="table_container">
                <table class="table_styles">
                    <thead class="table_header">
                        <tr>
                            <th class="tablehead_text">No.</th>
                            <th class="tablehead_text">{{ this.lang.pos_label1 }}</th>
                            <th class="tablehead_text">{{ this.lang.pos_label2 }}</th>
                            <th class="tablehead_text">{{ this.lang.pos_label3 }}</th>
                            <th class="tablehead_text">{{ this.lang.pos_label4 }}</th>
                            <th class="tablehead_text">{{ this.lang.pos_label5 }}</th>
                            <th class="tablehead_text">{{ this.lang.pos_label6 }}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item, index) in tableData" :key="index" :class="{'odd_row': index%2 === 0, 'even_row': index%2 !== 0}" class="table_body_border">
                            <td>{{ index + 1 }}</td>
                            <td>{{ item.deviceName }}</td>
                            <td>{{ item.serialNumber }}</td>
                            <td>{{ item.msisdn }}</td>
                            <td>{{ item.contractEntry }}</td>
                            <td>{{ item.contractRemaining }}</td>
                            <td>
                                <img src="../assets/actions_icon.png" alt="Logo" class="logo" @click="performAction(item)" />
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <!--Access denied-->
            <div class="loading-card" v-if="page_unavailable">
                <div class="loading-content">
                    <div class="loading-text">
                        <img src="../assets/multiply.png" class="img_style_denied_perms" style="margin-left: 15px;">
                        <p class="notiftext_title_denied_perms" style="margin-left: 20px;">{{
                            this.lang.popup_card_access_denied[0] }}</p>
                        <p class="notiftext_denied_perms">{{ this.lang.popup_card_access_denied[1] }}</p>
                        <button type="submit" class="mandev_btn text-center"
                            style="margin-left: 15px; margin-top: 20px;" @click="goBack">{{
                                this.lang.popup_card_access_denied[2] }}</button>
                    </div>
                </div>
            </div>
        </div>
        <BottomFooter ref="BottomFooterRef"></BottomFooter>
    </div>
</template>
            
<script>
    import SideNavbar from './SideNavbar.vue';
    import BottomFooter from './BottomFooter.vue';

    export default {
        components: {
        SideNavbar,
        BottomFooter,
        },
        data(){
            return{
                loading: false,
                success: false,
                tryagainWarning: false,
                page_unavailable: false,
                currentRole: localStorage.role,
                select_device_option: "ios",
                tableData: [
                ],
                lang:{
                    title:"",
                    second_title:"",
                    content:"",
                    msisdn_hint: '',
                    search_button: '',
                    pos_label1: '',
                    pos_label2: '',
                    pos_label3: '',
                    pos_label4: '',
                    pos_label5: '',
                    pos_label6: '',
                    popup_card_access_denied: [],
                },
                temp_return_tenure: {}
            };
        },
        methods: {
            performAction(v){
                localStorage.selected = v.serialNumber;
                localStorage.selectedname =v.usern;
                localStorage.selectedms=v.msisdn;
                localStorage.selectedcontact=v.contractEntry;
                localStorage.selectedmodel = v.deviceName;
                localStorage.selectedstorage = v.storage;
                localStorage.selectedStartContractDate = v.contractStart;
                localStorage.selectedContractTenure = v.contractTenure;
                localStorage.contractStatus = v.contractStatus;
                localStorage.selectedLastTrigger = v.lastTrigger;
                if (v.locked === 0){
                    this.$router.replace('/device-details');
                }
                else{
                    this.$router.replace('/device-lost-mode');
                }
            },
            refreshDeviceInfo() {
                const axios = require('axios');
                const data = JSON.stringify({
                    token: localStorage.token,
                    MSISDN: document.getElementById("devicename").value,
                    device_type: this.select_device_option
                })
                let config = {
                    method: 'POST',
                    maxBodyLength: Infinity,
                    url: 'https://api-master.ctrlytics.io/v1/dashboard/display-specific-devices',
                    headers: {
                    'Content-Type': 'application/json'
                    },
                    data: data
                };

                axios.request(config)
                    .then((response) => {
                    console.log(JSON.stringify(response.data));

                    if (response.data && response.data["200"]) {
                        // Loop through the "200" data and populate tableData
                        this.tableData = response.data["200"].map(item => ({
                        deviceName: item[1],
                        serialNumber: item[7],
                        msisdn: item[11],
                        contractEntry: item[16],
                        contractRemaining: item[4],
                        usern: item[3],
                        locked: item[14],
                        storage: item[2],
                        contractStart: item[5],
                        contractTenure: item[4],
                        contractStatus: item[15]
                        }));
                        this.tableData.forEach(this.forEachManipulation);
                        console.log(this.tableData)
                        this.success = true; // Set success to true if the data is loaded successfully
                    } else {
                        console.error("Invalid API response"); // Handle the case when the "200" key is missing or data is not in the expected format                    }
                        this.loading = true;
                        this.page_unavailable = true;
                    }

                    })
                    .catch((error) => {
                        console.log(error);
                    });
            },
            goBack() {
                this.$router.replace('/dashboard');
            },
            searchDevice(){
                console.log("Searching device...");
                this.loadData(document.getElementById("devicename").value, this.select_device_option)
            },
            async getUpdatedTimeFromLastCommand(selected_serial_number, selected_tenure, seleted_startContractDate) {
                //Implement API logic to determine last command contract tenure
                const axios = require('axios');
                const dataInput = JSON.stringify({
                        token: localStorage.token,
                        serial_number: selected_serial_number,
                        tenure: selected_tenure.toString(),
                        start_date: seleted_startContractDate,
                    })
                console.log(dataInput) 
                let config = {
                    method: 'post',
                    maxBodyLength: Infinity,
                    url: 'https://api-master.ctrlytics.io/v1/dashboard/device/trigger-time-lookups',
                    headers: { 
                        'Content-Type': 'application/json; charset=utf-8' 
                    },
                    data: dataInput
                };
                
                await axios.request(config)
                    .then((response) => {
                        console.log(JSON.stringify(response.data));

                        if (response.status === 200) {
                            this.temp_return_tenure = response.data;
                            
                        }
                        else {
                            console.log("Unable to retrieve extra data");
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                    });

            },
            loadData(managems, device_type){
                setTimeout(() => {
                    const axios = require('axios');

                    let config = {
                    method: 'get',
                    maxBodyLength: Infinity,
                    url: 'https://api-master.ctrlytics.io/v1/dashboard/display_all_devices?token='+localStorage.token+'&MSISDN='+managems+'&device_type='+device_type,
                    headers: { }
                    };

                    axios.request(config)
                    .then((response) => {
                    console.log(JSON.stringify(response.data));

                    if (response.data && response.data["200"]) {
                        // Loop through the "200" data and populate tableData
                        this.tableData = response.data["200"].map(item => ({
                        deviceName: item[1],
                        serialNumber: item[7],
                        msisdn: item[11],
                        contractEntry: item[16],
                        contractRemaining: item[4],
                        usern: item[3],
                        locked: item[14],
                        storage: item[2],
                        contractStart: item[5],
                        contractTenure: item[4],
                        contractStatus: item[15]
                        }));
                        this.tableData.forEach(this.forEachManipulation);
                        console.log(this.tableData)
                        this.success = true; // Set success to true if the data is loaded successfully
                    } else {
                        console.error("Invalid API response"); // Handle the case when the "200" key is missing or data is not in the expected format                    }
                        this.loading = true;
                        this.page_unavailable = true;
                    }

                    })
                    .catch((error) => {
                    console.log(error);
                    });
                }, 500); 
            },
            async forEachManipulation(item) {
                //Get each serial number, tenure, and start
                const serial_number = item.serialNumber;
                const tenure = item.contractTenure;
                const start_date = item.contractStart;
                console.log(start_date)

                await this.getUpdatedTimeFromLastCommand(serial_number, tenure, start_date);
                
                //item.banana = "Test Banana";
                item.contractRemaining = this.temp_return_tenure.contract_left_tenure;
                item.lastTrigger = this.temp_return_tenure.last_command_update;
            },
            async langchange(picker){
                console.log(picker)
                console.log(this.data)
                const response = await fetch("/managedevice.json");
                const data = await response.json();
                this.data = data
                this.lang = this.data[picker]
                this.$refs.BottomFooterRef.langchange(picker)
            }
        },
        mounted() {
            this.refreshDeviceInfo(""); // Call the loadData function when the component is mounted
            
        },
    }
</script>
            
<style scoped>
    .logo{
        width: 20px;
        cursor: pointer;
    }
    .layout{
        display: flex;
    }
    .regdevice_contents{
        flex: 1;
        padding-left: 16px;
        font-family: 'Century Gothic';
    }
    .regdevice_header{
        font-family: 'Century Gothic Bold';
        font-weight: bold;
        font-size: 24px;
        margin-top: 5px;
    }
    .regdevice_secondary_header{
        font-family: 'Century Gothic Bold';
        font-weight: bold;
        font-size: 20px;
    }
    .regdevice_secondary_container{
        margin-top: 5px;
    }
    .contents_header_text{
        font-size: 14px;
        margin-top: -15px;
    }
    .form_margin{
        margin-top: -6px;
    }
    .custom_input_form{
        background-color: #ffffff;
        height: 35px;
        width: 300px;
        text-decoration: none solid rgba(0, 0, 0, 0.87);
        border-color: rgba(1, 48, 66, 0.2) !important;
        word-spacing: 0px;
        letter-spacing: 0.131118px;
        font-size: 12px !important;
        box-sizing: border-box;
        outline: rgba(0, 0, 0, 0.87) none 0px;
    }
    .searchbar_container{
        display: flex;
    }
    .custom_input_form:focus{
        outline: none !important;
        box-shadow: none !important;
        border-color: rgba(1, 48, 66, 0.8) !important;
        border-width: 2.5px;
        background-color: #ffffff;
        color: #000000;
        font-size: 12px;
    }
    ::placeholder{
        font-size: 12px;
    }
    .table_container{
        margin-top: 15px;
        max-width: 1100px;
        max-height: 370px;
        overflow-y: auto;
    }
    .table_container::-webkit-scrollbar{
        width: 8px;
    }
    .table_container::-webkit-scrollbar-thumb {
        background-color: #888;
        border-radius: 4px;
    }
    .table_container::-webkit-scrollbar-track {
        background-color: #eee;
        border-radius: 4px;
    }
    .table_styles{
        width: 100%;

    }
    thead tr{
        height: 50px;
    }
    th, td{
        text-align: center;
        padding: 16px;
    }
    .odd_row{
        background-color: #ffffff;
    }
    .even_row{
        background-color: #e6e6e6;
    }
    .table_header{
        background-color: #cccccc;
        text-align: center;
        border-bottom: 1px solid #989898;
    }
    .table_body_border{
        border-bottom: 1px solid #989898;
    }
    .tablehead_text{
        font-family: "Century Gothic Bold", sans-serif;
        font-size: 14px;
        text-align: center;
    }
    .overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent overlay */
        z-index: 9998; /* Below the loading content */
    }
    .editacc_btn{
        margin-left: 10px;
        width: 100px;
        height: 35px;
        font-family: 'Century Gothic Bold', sans-serif;
        font-size: 11px;
        border: none;
        border-radius: 5px;
        padding: 0.3vw;
        background-color: #ffd600;
        color: #013042;
        transition: background-color 0.3s ease;
        font-weight: 700;
    }
    .editacc_btn:hover{
        filter: brightness(90%);
    }

    .loading-card {
        position: fixed;
        width: 500px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: white;
        padding: 20px;
        border-radius: 28px;
        filter: brightness(98%);
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
        z-index: 9999;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .loading-content {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
    }

    .loading-text {
        justify-items: center;
        display: grid;
        font-family: CenturyGothic, sans-serif;
        text-align: center;
    }

    .notiftext_denied_perms {
        font-family: 'Century Gothic';
        text-align: center !important;
        font-size: 12px;
        color: #000000;
        margin-bottom: 8px;
    }

    .mandev_btn {
        text-align: center;
        width: 150px;
        height: 30px;
        margin-bottom: 5px;
        font-family: 'Century Gothic Bold', sans-serif;
        font-size: 10px;
        border: none;
        border-radius: 28px;
        padding: 0.3vw;
        background-color: #ffd600;
        color: #013042;
        transition: background-color 0.3s ease;
        font-weight: 700;
    }

    .mandev_btn:hover {
        filter: brightness(90%);
    }

    .notiftext_title_denied_perms {
       font-family: 'Century Gothic Bold';
        font-size: 16px;
        color: #000000;
        margin-bottom: 2px;
    }

    .img_style_denied_perms {
        width: 80px;
        height: 80px;
        margin-bottom: 10px;
        left: 10px;
    }

    @media(min-width: 1900px){
        .table_container{
            max-width: 1750px;
            max-height: 500px;
        }
    }
    @media(min-width: 1500px){
        .table_container{
            max-width: 1400px;
            max-height: 570px;
        }
    }
</style>