<template>
  <div class="layout">
    <div class="overlay" v-if="overlay"></div>
    <div style="z-index: 100 !important;">
      <SideNavbar @button-click="langchange"></SideNavbar>
    </div>
    <div class="dashboard_contents">
      <div class="dashboard_title">
        {{ this.lang.title }}
      </div>
      <div class="dashboard_role">
          Current Role : {{ this.currentRole }}
      </div><br>
      <div class="dashboard_secondary">
        {{ this.lang.second_title }}
      </div>
      <div class="dashboard_text_contents">
        {{ this.lang.content }}
      </div>
    </div>
    <div class="loading-card" v-if="page_unavailable">
      <div class="loading-content">
        <div class="loading-text">
          <img src="../assets/multiply.png" class="img_style_denied_perms" style="margin-left: 140px;">
          <p class="notiftext_title_denied_perms" style="margin-left: 95px;">{{ this.lang.popup_card_access_denied[0]
            }}</p>
          <p class="notiftext_denied_perms">{{ this.lang.popup_card_access_denied[1] }}</p>
          <button type="submit" class="mandev_btn text-center" style="margin-left: 100px; margin-top: 20px;"
            @click="backToLogin">{{ this.lang.popup_card_access_denied[2] }}</button>
        </div>
      </div>
    </div>
    <BottomFooter ref="BottomFooterRef"></BottomFooter>
  </div>
</template>

<script>
import SideNavbar from './SideNavbar.vue';
import BottomFooter from './BottomFooter.vue';

export default {
  data() {
    return {
      data: [],
      overlay: false,
      page_unavailable: false,
      lang: {
        title: "",
        second_title: "",
        content: "",
        popup_card_access_denied: []
      },
      currentRole:localStorage.role
    }
  },
  components: {
    SideNavbar,
    BottomFooter,
  },
  mounted() {
    this.checkLogin();
  },
  methods: {
    async langchange(picker) {
      console.log(picker)
      console.log(this.data)
      const response = await fetch("/startdash.json");
      const data = await response.json();
      this.data = data
      this.lang = this.data[picker]
      this.lang.second_title = this.lang.second_title.replace("currentUser",localStorage.currentUser)
      console.log(this.lang.second_title)
      this.$refs.BottomFooterRef.langchange(picker)
    },
    checkLogin() {
      //Enable overlay
      this.overlay = true;
      //Prep API
      const data = JSON.stringify({
        token: localStorage.token
      });
      const axios = require('axios');
      let config = {
        method: 'POST',
        maxBodyLength: Infinity,
        url: 'https://api-master.ctrlytics.io/v1/login/validity-check',
        headers: {
          'Content-Type': 'application/json'
        },
        data: data
      };
      //Run API
      axios.request(config)
        .then((response) => {
          if (response.status === 200) {
            this.overlay = false;
          }
          else {
            this.page_unavailable = true;
          }
        })
        .catch((error) => {
          console.log(error);
          this.page_unavailable = true;
        });
    },
    backToLogin() {
      this.$router.replace('/');
    }
  }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.layout {
  display: flex;
}

.loading-card {
  position: fixed;
  width: 400px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  border-radius: 28px;
  filter: brightness(98%);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
}

.img_style_denied_perms {
  width: 80px;
  height: 80px;
  margin-bottom: 10px;
  left: 10px;
}

.notiftext_title_denied_perms {
  font-family: 'Century Gothic Bold';
  font-size: 16px;
  color: #000000;
  margin-bottom: 2px;
}

.notiftext_denied_perms {
  font-family: 'Century Gothic';
  text-align: center !important;
  font-size: 12px;
  color: #000000;
  margin-bottom: 8px;
}

.mandev_btn {
  text-align: center;
  width: 150px;
  height: 30px;
  margin-bottom: 5px;
  font-family: 'Century Gothic Bold', sans-serif;
  font-size: 10px;
  border: none;
  border-radius: 28px;
  padding: 0.3vw;
  background-color: #ffd600;
  color: #013042;
  transition: background-color 0.3s ease;
  font-weight: 700;
}

.mandev_btn:hover {
  filter: brightness(90%);
}

.dashboard_contents {
  flex: 1;
  padding-left: 16px;
}

.dashboard_role{
  float:left;
}

.dashboard_title {
  font-family: 'Century Gothic Bold';
  font-weight: bold;
  font-size: 28px;
  margin-top: 11px;
}

.dashboard_secondary {
  font-family: 'Century Gothic Bold';
  font-weight: bold;
  font-size: 18px;
  margin-top: 15px;
}

.dashboard_text_contents {
  font-family: 'Century Gothic';
  font-size: 14px;
  margin-top: 10px;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  /* Semi-transparent overlay */
  z-index: 9998;
  /* Below the loading content */
}
</style>