<template>
  <div class="layout">
    <div class="overlay" v-if="overlayup"></div>
    <div style="z-index: 100 !important;">
      <SideNavbar @button-click="langchange"></SideNavbar>
    </div>
    <div class="faq_contents">
      <div class="faq_header">
        Manage Notification Templates
      </div>
      <div class="dashboard_role" style="margin-left: 13px;">
          Current Role : {{ this.currentRole }}
       </div>
      <div class="faq_container">
        <div class="table_container">
          <table class="table_styles">
            <thead class="table_header">
              <tr>
                <th class="tablehead_text">{{ this.lang.header1 }}</th>
                <th class="tablehead_text">{{ this.lang.header2 }}</th>
                <th class="tablehead_text">{{ this.lang.header3 }}</th>
                <th class="tablehead_text">{{ this.lang.header4 }}</th>
                <th class="tablehead_text">{{ this.lang.header5 }}</th>
                <th class="tablehead_text">{{ this.lang.header6 }}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(input_data, index) in template_data" :key="index"
                :class="{ 'odd_row': index % 2 === 0, 'even_row': index % 2 !== 0 }" class="table_body_border">

                <td class="tablecolumn_text">{{ input_data[1] }}</td>
                <td class="tablecolumn_text">{{ input_data[2] }}</td>
                <td class="tablecolumn_text">{{ input_data[3] }}</td>
                <td class="tablecolumn_text">{{ input_data[4] }}</td>
                <td class="tablecolumn_text">{{ input_data[5] }}</td>
                <td>
                  <button type="submit" class="maangeacc_btn" @click="onOpenEditTemplate(input_data[0])">{{
                    this.lang.manage_button }}</button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
  <div class="lostmode-card">
    <div class="row">
      <div class="lostmode-text">
        {{ this.lang.popup_card_copywriting[0] }}
      </div>
      <div class="lostmode-content">
        <div class="col-md-6">
          <div style="padding: 10px;">
            <label class="input_header_style" for="lm_category">{{ this.lang.popup_card_copywriting[1] }}:</label>
            <select type="select-category" @change="handleSelectionOfDue"
              class="form-control contact_input_form" id="lm_category" v-model="formData.category" required>
              <!--<option value="before-due">Before Due</option>-->
              <option value="due-date">Due Date</option>
              <option value="after-due">After Due</option>
            </select>
          </div>
          <div style="padding: 10px;">
            <label class="input_header_style" for="lm_day_count">{{ this.lang.popup_card_copywriting[3] }}:</label>
            <input type="text" class="form-control contact_input_form" id="lm_day_count"
              :placeholder="this.lang.popup_card_copywriting[4]" v-model="formData.day_count" required
              :disabled="days_input_disabled" />
          </div>
          <div style="padding: 10px;">
            <label class="input_header_style" for="lm_duration">{{ this.lang.popup_card_copywriting[5] }}:</label>
            <input type="text" class="form-control contact_input_form" id="lm_duration"
              :placeholder="this.lang.popup_card_copywriting[6]" v-model="formData.lm_duration" required />
          </div>
          <div style="padding: 10px;">
            <label class="input_header_style" for="lm_message">{{ this.lang.popup_card_copywriting[9] }}:</label>
            <input type="text" @input="checkMessageCharacterLimit()" class="form-control contact_input_form"
              id="lm_message" :placeholder="this.lang.popup_card_copywriting[10]" v-model="formData.lm_message"
              style="height: 100px;" required />
            <p class="lm-counter">{{ formData.lm_message.length }}/192</p>
          </div>
          <div style="padding: 10px;">
            <label class="input_header_style" for="lm_contact">{{ this.lang.popup_card_copywriting[11] }}:</label>
            <input type="text" @input="checkContactCharacterLimit()" class="form-control contact_input_form"
              id="contact" :placeholder="this.lang.popup_card_copywriting[12]" v-model="formData.lm_contact" required />
            <p class="lm-counter">{{ formData.lm_contact.length }}/24</p>
          </div>
          <div style="padding: 10px;">
            <label class="input_header_style" for="lm_footnote">{{ this.lang.popup_card_copywriting[13] }}:</label>
            <input type="text" @input="checkFootnoteCharacterLimit()" class="form-control contact_input_form"
              id="lm_footnote" :placeholder="this.lang.popup_card_copywriting[14]" v-model="formData.lm_footnote"
              required />
            <p class="lm-counter">{{ formData.lm_footnote.length }}/36</p>
          </div>
          <div style="text-align: center; margin-top: 10px; ">
            <button type="submit" class="enable_btn" @click="saveTemplateChange">{{ this.lang.popup_card_copywriting[16]
              }}</button>
            <button type="submit" class="enable_btn" @click="cancelTemplateForm"
              style="background-color: red; color: #fff;">{{ this.lang.popup_card_copywriting[15] }}</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="loading-card" v-if="loadingCommand">
    <div class="loading-content">
      <div class="loading-text">
        <img src="../assets/loading.gif" class="img_style" style="margin-left: 20px;">
        <p class="notiftext_title">{{ this.lang.popup_card_loading[1] }}</p>
      </div>
    </div>
  </div>
  <div class="loading-card" v-if="templateCommandSuccess">
    <div class="loading-content">
      <div class="loading-text">
        <img src="../assets/check.png" class="img_style" style="margin-left: 90px;">
        <p class="notiftext_title" style="margin-left: 10px;">{{ this.lang.popup_card_success[0] }}</p>
        <p class="notiftext">{{ this.lang.popup_card_success[1] }}</p>
        <button type="submit" class="mandev_btn text-center" style="margin-left: 55px; margin-top: 20px;"
          @click="commandEditTemplateSuccessDone">{{ this.lang.popup_card_success[2] }}</button>
      </div>
    </div>
  </div>
  <div class="loading-card" v-if="templateCommandFail">
    <div class="loading-content">
      <div class="loading-text">
        <img src="../assets/multiply.png" class="img_style" style="margin-left: 130px;">
        <p class="notiftext_title" style="margin-left: 70px;">{{ this.lang.popup_card_fail[0] }}</p>
        <p class="notiftext">{{ this.lang.popup_card_fail[1] }}</p>
        <button type="submit" class="mandev_btn text-center" style="margin-left: 95px; margin-top: 20px;"
          @click="commandEditTemplateFailDone">{{ this.lang.popup_card_fail[2] }}</button>
      </div>
    </div>
  </div>
  <div class="loading-card" v-if="page_unavailable">
    <div class="loading-content">
      <div class="loading-text">
        <img src="../assets/multiply.png" class="img_style_denied_perms" style="margin-left: 135px;">
        <p class="notiftext_title_denied_perms" style="margin-left: 120px;">{{ this.lang.popup_card_access_denied[0] }}
        </p>
        <p class="notiftext_denied_perms">{{ this.lang.popup_card_access_denied[1] }}</p>
        <button type="submit" class="mandev_btn text-center" style="margin-left: 100px; margin-top: 20px;"
          @click="goBack">{{ this.lang.popup_card_access_denied[2] }}</button>
      </div>
    </div>
    
  </div>
  <BottomFooter ref="BottomFooterRef"></BottomFooter>
</template>

<script>
import SideNavbar from './SideNavbar.vue';
import BottomFooter from './BottomFooter.vue';

export default {
  data() {
    return {
      page_unavailable: false,
      arrayLength: 0,
      arrayLength2: 0,
      lang: {
        title: "",
        second_title: "",
        content: "",
        question: [],
        explaination: [],
        popup_card_copywriting: [],
        popup_card_loading: [],
        popup_card_success: [],
        popup_card_fail: [],
        popup_card_access_denied: []
      },
      formData: {
        category: "Select Option",
        day_count: 0,
        lm_duration: 0,
        lm_message: "",
        lm_message_length: 0,
        lm_contact: "",
        lm_contact_length: 0,
        lm_footnote: "",
        lm_footnote_length: 0
      },
      template_data: [],
      overlayup: false,
      newtemplate: false,
      edittemplate: false,
      loadingCommand: false,
      templateCommandSuccess: false,
      templateCommandFail: false,
      days_input_disabled: false,
      currentRole: localStorage.role,
      temp_template_id: -1
    }
  },
  mounted() {
    this.getTemplate();
  },
  components: {
    SideNavbar,
    BottomFooter,
  },
  methods: {
    async langchange(picker) {
      console.log(picker)
      console.log(this.data)
      const response = await fetch("/managetemplates.json");
      const data = await response.json();
      this.data = data
      this.lang = this.data[picker]
      this.$refs.BottomFooterRef.langchange(picker);
    },
    addPhoneCode() {

    },
    handleSelectionOfDue() {
      if (this.formData.category == 'due-date') {
        this.formData.day_count = 0
        this.days_input_disabled = true
      } else {
        this.formData.day_count = ''
        this.days_input_disabled = false
      }
    },
    goBack() {
      this.$router.replace('/dashboard');
    },
    checkMessageCharacterLimit() {
      if (this.formData.lm_message.length > 96) {
        this.formData.lm_message = this.formData.lm_message.substring(0, 192);
      }
    },
    checkContactCharacterLimit() {
      if (this.formData.lm_contact.length > 24) {
        this.formData.lm_contact = this.formData.lm_contact.substring(0, 24);
      }
    },
    checkFootnoteCharacterLimit() {
      if (this.formData.lm_footnote.length > 36) {
        this.formData.lm_footnote = this.formData.lm_footnote.substring(0, 36);
      }
    },
    onOpenEditTemplate(index) {
      // Grab params
      this.overlayup = true;

      // Get the element with the class 'lostmode-content'
      var lostmodeContent = document.querySelector('.lostmode-card');

      // Check if the element exists
      if (lostmodeContent) {
        // Change the display property to 'flex'
        lostmodeContent.style.display = 'flex';
      } else {
        console.error("Element with class 'lostmode-content' not found.");
      }
      console.log(index);

      const data = JSON.stringify({
        token: localStorage.token,
        template_id: index.toString()
      })

      console.log(data);
      // Use API to get temlpate data
      const axios = require('axios');
      let config = {
        method: 'POST',
        maxBodyLength: Infinity,
        url: 'https://api-master.ctrlytics.io/v1/lost_mode/template/retrieve/specific',
        headers: {
          'Content-Type': 'application/json'
        },
        data: data
      };
      // Run API
      axios.request(config)
        .then((response) => {
          this.newtemplate = true;
          console.log(JSON.stringify(response.data));
          this.formData.category = response.data.entry_type;
          this.temp_template_id = index.toString();
          this.formData.day_count = response.data.day_count;
          this.formData.lm_duration = response.data.lost_mode_duration;
          this.formData.lm_message = response.data.message;
          this.formData.lm_footnote = response.data.footnote;
          this.formData.lm_contact = response.data.number;

          setTimeout(function () {
            //var dropdown = document.getElementById("lm_category");
            //var optionValue = response.data.entry_type;

            // Loop through each option in the dropdown
            //for (var i = 0; i < dropdown.options.length; i++) {
              // Check if the option value matches the desired value
              //if (dropdown.options[i].value === optionValue) {
                // Set the selected attribute to true for the matching option
                //dropdown.options[i].selected = true;
                // Optionally, trigger a change event if you need to simulate user interaction
                //dropdown.dispatchEvent(new Event('change'));
                //break; // Exit the loop since the value has been found
              //}
            //}
          }, 2000);
        })
        .catch((error) => {
          console.log(error);
        });
      // Trigger popup
      console.log("Popup is open");

      // Auto-select
      // Assuming your dropdown has an id of "myDropdown"



      // Assuming the value you want to select is "option_value"


      //while (!dropdown) {
      //  console.log("Still waiting")
      //  dropdown = document.getElementById("lm_category");
      //}


    },
    saveTemplate() {
      //Trigger loading popup
      this.newtemplate = false;
      this.loadingCommand = true;
      //Use API to get template data
      //Popup done
    },
    openTemplateForm() {
      this.overlayup = true;
      this.newtemplate = true;
    },
    cancelTemplateForm() {
      this.overlayup = false;
      var lostmodeContent = document.querySelector('.lostmode-card');
      if (lostmodeContent) {
        // Change the display property to 'flex'
        lostmodeContent.style.display = 'none';
      } else {
        console.error("Element with class 'lostmode-content' not found.");
      }
    },
    saveTemplateChange() {
      //Load accordingly
      var lostmodeContent = document.querySelector('.lostmode-card');
      if (lostmodeContent) {
        // Change the display property to 'flex'
        lostmodeContent.style.display = 'none';
      } else {
        console.error("Element with class 'lostmode-content' not found.");
      }
      this.loadingCommand = true;

      //Start API request
      const data = JSON.stringify({
        token: localStorage.token,
        template_id: this.temp_template_id,
        category: this.formData.category,
        day_count: this.formData.day_count.toString(),
        duration: this.formData.lm_duration,
        message: this.formData.lm_message,
        contact: this.formData.lm_contact,
        footnote: this.formData.lm_footnote,
      })

      console.log(data);
      // Use API to get temlpate data
      const axios = require('axios');
      let config = {
        method: 'POST',
        maxBodyLength: Infinity,
        url: 'https://api-master.ctrlytics.io/v1/lost_mode/template/edit',
        headers: {
          'Content-Type': 'application/json'
        },
        data: data
      };

      //Run API Request
      axios.request(config)
        .then((response) => {
          console.log(response.data);
          this.loadingCommand = false;
          this.templateCommandSuccess = true;
        })
        .catch((error) => {
          console.log(error);
          this.loadingCommand = false;
          this.templateCommandFail = true;
        });

    },
    getTemplate() {
      const axios = require('axios');
      let config = {
        method: 'POST',
        maxBodyLength: Infinity,
        url: 'https://api-master.ctrlytics.io/v1/lost_mode/template/retrieve',
        headers: {
          'Content-Type': 'application/json; charset=utf-8'
        },
        data: JSON.stringify({
          token: localStorage.token
        })
      };

      axios.request(config)
        .then((response) => {
          console.log(response);
          if (response.status === 200) {
            console.log("okei");
            console.log(JSON.stringify(response.data.data));
            this.template_data = response.data.data;
          }
          else {
            this.overlayup = true;
            this.page_unavailable = true;
          }
          
        })
        .catch((error) => {
          if (error.response.status === 403 || error.response.status === 404) {
            this.overlayup = true;
            this.page_unavailable = true;
          }
          console.log(error);
        });
    },
    commandEditTemplateSuccessDone() {
      this.overlayup = false;
      window.location.reload();
      this.templateCommandSuccess = false;
    },
    commandEditTemplateFailDone() {
      this.overlayup = false;
      window.location.reload();
      this.templateCommandFail = false;
    }
  }
}
</script>

<style scoped>
.table_container {
  margin-top: 15px;
  max-width: 1500px;
  max-height: 1000px;
  overflow-y: auto;
}

.lm-counter {
  text-align: right;
  font-size: small;
  margin-left: 320px;

}

.table_container::-webkit-scrollbar {
  width: 8px;
}

.table_container::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 4px;
}

.table_container::-webkit-scrollbar-track {
  background-color: #eee;
  border-radius: 4px;
}

.table_styles {
  width: 100%;

}

thead tr {
  height: 50px;
}

th,
td {
  text-align: center;
  padding: 16px;
}

.odd_row {
  background-color: #ffffff;
}

.even_row {
  background-color: #e6e6e6;
}

.table_header {
  background-color: #cccccc;
  text-align: center;
  border-bottom: 1px solid #989898;
}

.table_body_border {
  border-bottom: 1px solid #989898;
}

.tablehead_text {
  font-family: "Century Gothic Bold", sans-serif;
  font-size: 14px;
  text-align: center;
}

.layout {
  display: flex;
}

.faq_contents {
  flex: 1;
  padding-left: 16px;
  font-family: 'Century Gothic';
}

.faq_header {
  font-family: 'Century Gothic Bold';
  font-weight: bold;
  font-size: 24px;
  margin-top: 21px;
  margin-left: 12px;
}

.faq_container {
  padding-left: 30px;
  text-align: justify;
  margin-top: 20px;
  height: 75vh;
  max-width: 90vw;
  overflow-y: auto;
}

.faq_container::-webkit-scrollbar {
  width: 8px;
}

.faq_container::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 4px;
}

.faq_container::-webkit-scrollbar-track {
  background-color: #eee;
  border-radius: 4px;
}

.addtemplate_btn {
  margin-top: 50px;
  margin-left: 80%;
  width: 150px;
  height: 53px;
  font-family: 'Century Gothic Bold', sans-serif;
  font-size: 11px;
  border: none;
  border-radius: 5px;
  padding: 0.3vw;
  background-color: #ffd600;
  color: #013042;
  transition: background-color 0.3s ease;
  font-weight: 700;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  /* Semi-transparent overlay */
  z-index: 9998;
  /* Below the loading content */
}


.addtemplate_btn:hover {
  filter: brightness(90%);
}

/*Lost Mode Enable Form*/
.lostmode-card {
  position: absolute;
  width: 500px;
  height: 800px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  z-index: 9999;
  display: none;
}

.lostmode-content {
  display: absolute;
  padding-bottom: 50px;
  padding-left: 11%;
}

.lostmode-text {
  font-family: 'Century Gothic Bold', sans-serif;
  font-size: 20px;
  padding-left: 20px;
  margin-bottom: -10px;
}

/*Lost Mode Form Style*/
.input_header_style {
  display: inline-block;
  font-family: 'Century Gothic Bold';
  font-size: 12px;
  color: #013042;
  margin-bottom: 2px;
}

.contact_input_form {
  display: absolute !important;
  left: 80% !important;
  background-color: #ffffff;
  height: 35px;
  width: 350px;
  text-decoration: none solid rgba(0, 0, 0, 0.87);
  border-color: rgba(1, 48, 66, 0.2) !important;
  word-spacing: 0px;
  letter-spacing: 0.131118px;
  font-size: 12px !important;
  box-sizing: border-box;
  outline: rgba(0, 0, 0, 0.87) none 0px;
}

.contact_input_form:focus {
  outline: none !important;
  box-shadow: none !important;
  border-color: rgba(1, 48, 66, 0.8) !important;
  border-width: 2.5px;
  background-color: #ffffff;
  color: #000000;
  font-size: 12px;
}

.enable_btn {
  text-align: center;
  width: 140px;
  height: 30px;
  font-family: 'Century Gothic Bold', sans-serif;
  font-size: 10px;
  border: none;
  border-radius: 28px;
  padding: 0.2vw;
  background-color: #ffd600;
  color: #013042;
  transition: background-color 0.3s ease;
  margin-top: 10px;
  margin-left: 110px;
  font-weight: 700;
}

.enable_btn:hover {
  filter: brightness(90%);
}

.cancel_btn {
  text-align: center;
  width: 140px;
  height: 30px;
  font-family: 'Century Gothic Bold', sans-serif;
  font-size: 10px;
  border-radius: 28px;
  padding: 0.3vw;
  background-color: #ffffff;
  border-color: #013042;
  color: #013042;
  transition: background-color 0.3s ease;
  font-weight: 700;
}

.cancel_btn:hover {
  filter: brightness(90%);
}

.loading-card {
  position: fixed;
  width: 400px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  border-radius: 28px;
  filter: brightness(98%);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
}

.maangeacc_btn {
  text-align: center;
  width: 100px;
  height: 30px;
  font-family: 'Century Gothic Bold', sans-serif;
  font-size: 10px;
  border: none;
  border-radius: 28px;
  padding: 0.3vw;
  background-color: #ffd600;
  color: #013042;
  transition: background-color 0.3s ease;
  font-weight: 700;
}

.img_style {
  width: 80px;
  height: 80px;
  margin-bottom: 10px;
}

.img_style_denied_perms {
  width: 80px;
  height: 80px;
  margin-bottom: 10px;
  left: 10px;
}

.notiftext_title {
  font-family: 'Century Gothic Bold';
  font-size: 16px;
  color: #000000;
  margin-bottom: 2px;
}

.notiftext_title_denied_perms {
  font-family: 'Century Gothic Bold';
  font-size: 16px;
  color: #000000;
  margin-bottom: 2px;
}

.notiftext {
  font-family: 'Century Gothic';
  text-align: center !important;
  font-size: 12px;
  color: #000000;
  margin-bottom: 8px;
}

.notiftext_denied_perms {
  font-family: 'Century Gothic';
  text-align: center !important;
  font-size: 12px;
  color: #000000;
  margin-bottom: 8px;
}

.mandev_btn {
  text-align: center;
  width: 150px;
  height: 30px;
  margin-bottom: 5px;
  font-family: 'Century Gothic Bold', sans-serif;
  font-size: 10px;
  border: none;
  border-radius: 28px;
  padding: 0.3vw;
  background-color: #ffd600;
  color: #013042;
  transition: background-color 0.3s ease;
  font-weight: 700;
}

.mandev_btn:hover {
  filter: brightness(90%);
}

@media(min-width: 1500px) {
  .faq_container {
    max-width: 1400px;
    height: 80vh;
  }
}

@media(min-width: 1900px) {
  .faq_container {
    max-width: 1700px;
    height: 80vh;
  }
}

@media(max-width: 376px) {
  .faq_header {
    font-size: 18px;
  }

  .faq_secondary_header {
    font-size: 15px;
  }

  .qtext {
    font-size: 15px;
  }

  .atext {
    font-size: 14px;
  }

  .maangeacc_btn {
    padding-left: 15px;
  }

  .removeacc_btn {
    padding-left: 15px;
  }
}
</style>