<template>
<div class="layout">
    <div style="z-index: 100 !important;">
    <SideNavbar></SideNavbar>
    </div>
    <div class="faq_contents">
    <BackButton @click="goBack"></BackButton>
    <div class="faq_header">
        Device Location
    </div>
    <div class="faq_secondary_header">
        Here is the location of the corresponding device.
    </div>
    </div>
</div>
</template>
    
<script>
import BackButton from './BackButton.vue'
import SideNavbar from './SideNavbar.vue';

export default {
    components: {
    SideNavbar,
    BackButton,
    },
    methods: {
    goBack() {
        this.$router.replace('/device-lost-mode'); // Go back one step in the history
    },
    }
}
</script>
    
<style scoped>
.layout{
    display: flex;
}
.faq_contents{
    flex: 1;
    padding-left: 16px;
    font-family: 'Century Gothic';
}
.faq_header{
    font-family: 'Century Gothic Bold';
    font-weight: bold;
    font-size: 24px;
    margin-top: 11px;
}
.faq_secondary_header{
    font-family: 'Century Gothic Bold';
    font-weight: bold;
    font-size: 16px;
    margin-top: 15px;
}
.faq_container{
    margin-top: 20px;
    max-height: 600px;
    max-width: 1300px;
    overflow-y: auto;
}
.qtext{
    font-family: 'Century Gothic Bold';
    color: #013042;
    font-weight: 700;
    font-size: 18px;
}
.atext{
color: #013042;
font-size: 16px;
margin-top: -12px;
}
</style>