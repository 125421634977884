<template>
    <div class="layout">
        <div class="overlay" v-if="loading"></div>
        <div style="z-index: 100 !important;">
        <SideNavbar @button-click="langchange"></SideNavbar>
    </div>
    <div class="dashboard_contents">
      <div class="dashboard_title">
        {{ this.lang.header_title[0] }}
      </div>
      <div class="dashboard_text_contents">
        {{ this.lang.header_title[1] }}
      </div>
      <div class="options">
        <div class="option-item" @click=manageTemplatesRouter>
            <img src="../assets/template_icon.png" alt="Manage Templates" class="icon-selection" style="float: left;"/>
            <div class="text-items">
                <p class="text-title">{{ this.lang.selection_manage_templates[0] }}</p>
                <p class="text-description">{{ this.lang.selection_manage_templates[1] }}</p>
            </div>
        </div>
        <div class="option-item" @click="this.$router.replace('/credit-manager')">
            <img src="../assets/transaction.png" alt="Manage Accounts" class="icon-selection" style="float: left;"/>
            <div class="text-items">
                <p class="text-title">{{ this.lang.selection_credit_system[0] }}</p>
                <p class="text-description">{{ this.lang.selection_credit_system[1] }}</p>
            </div>
        </div>
     </div>
    </div>
    <!--Access denied-->
    <div class="loading-card" v-if="page_unavailable">
            <div class="loading-content">
                <div class="loading-text">
                    <img src="../assets/multiply.png" class="img_style_denied_perms" style="margin-left: 15px;">
                    <p class="notiftext_title_denied_perms" style="margin-left: 20px;">{{
                        this.lang.popup_card_access_denied[0] }}</p>
                    <p class="notiftext_denied_perms">{{ this.lang.popup_card_access_denied[1] }}</p>
                    <button type="submit" class="mandev_btn text-center"
                        style="margin-left: 15px;" @click="goBack">{{
                        this.lang.popup_card_access_denied[2] }}</button>
                </div>
            </div>
        </div>
    </div>
    <BottomFooter ref="BottomFooterRef"></BottomFooter>
</template>
<script>
    import SideNavbar from './SideNavbar.vue';
    import BottomFooter from './BottomFooter.vue';

    export default {
        components: {
            SideNavbar,
            BottomFooter,
        },
        data() {
            return{
                loading: false,
                page_unavailable: false,
                lang:{
                    popup_card_access_denied: [
                        "Access Denied",
                        "Please return to the homepage",
                        "OK"
                    ],
                    header_title: [],
                    selection_manage_templates: [],
                    selection_credit_system: []
                }
            };
        },
        methods: {
            async langchange(picker){
                console.log(picker)
                console.log(this.data)
                const response = await fetch("/settings.json");
                const data = await response.json();
                this.data = data
                this.lang = this.data[picker]
                this.$refs.BottomFooterRef.langchange(picker)
            },
            manageTemplatesRouter() {
                this.$router.replace('/manage-templates');
            }
        },
        mounted() {
            this.langchange()
        }
    }
</script>
<style scoped>

    .loading-card {
        position: fixed;
        width: 500px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: white;
        padding: 20px;
        border-radius: 28px;
        filter: brightness(98%);
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
        z-index: 9999;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .loading-content {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
    }

    .text-items {
        padding-top: 50px;
        padding-right: 10px;
    }

    .text-description {
       font-size: 16px;
    }

    .text-title {
       font-family: 'Century Gothic Bold';
       font-weight: bold;
       font-size: 36px;
    }

    .loading-text {
        justify-items: center;
        display: grid;
        font-family: CenturyGothic, sans-serif;
        text-align: center;
    }

    .layout{
        display: flex;
    }

    .overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent overlay */
        z-index: 9998; /* Below the loading content */
    }

    .notiftext_denied_perms {
        font-family: 'Century Gothic';
        text-align: center !important;
        font-size: 12px;
        color: #000000;
        margin-bottom: 8px;
    }

    .mandev_btn {
        text-align: center;
        width: 150px;
        height: 30px;
        margin-bottom: 5px;
        font-family: 'Century Gothic Bold', sans-serif;
        font-size: 10px;
        border: none;
        border-radius: 28px;
        padding: 0.3vw;
        background-color: #ffd600;
        color: #013042;
        transition: background-color 0.3s ease;
        font-weight: 700;
    }

    .mandev_btn:hover {
        filter: brightness(90%);
    }

    .notiftext_title_denied_perms {
       font-family: 'Century Gothic Bold';
        font-size: 16px;
        color: #000000;
        margin-bottom: 2px;
    }

    .img_style_denied_perms {
        width: 80px;
        height: 80px;
        margin-bottom: 10px;
        left: 10px;
    }

    @media (max-width: 900px) {
        .options {
            width: 800px;
        }
    }

    .options {
        margin-left: -1px;
        margin-top: 30px;
        max-width: 98%;
        height: auto;
        color: #000;
        justify-content: space-between;
    }

    .option-item {
        margin-top: 20px;
        border: 2px solid black;
        border-width: 3px;
        border-radius: 15px;
        height: 200px;
    }

    .option-item:hover {
        background-color: #ffd600;
        filter: brightness(105%);
        cursor: pointer;
    }

    .dashboard_contents {
       flex: 1;
       padding-left: 16px;
    }

    .dashboard_title {
       font-family: 'Century Gothic Bold';
       font-weight: bold;
       font-size: 28px;
       margin-top: 11px;
    }
    
    .dashboard_text_contents {
       font-family: 'Century Gothic';
       font-size: 14px;
       margin-top: 10px;
    }

    .icon-selection {
        margin-left: 10px; 
        padding: 30px;
        width: 200px;
        height: 200px;
    }

</style>